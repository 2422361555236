const localUserData = localStorage.getItem('userData') || '';
const userData = localUserData ? JSON.parse(localUserData) : {};
const userGroups = userData?.user?.usergroups ? userData.user.usergroups.map(x => x.group) : [];
const adminAccess = userData?.user?.isAdmin || userGroups.includes('Sourcing') || false;

const localstate = {
    pdfOutput: 'download', // download | blob
    loading: false,
    submitted: false,
    adminAccess,
    language: { label: 'English', value: 'en' },
    files: [],
    filesToUpload: [],
    
    selected: {
        questionnaire: null,
        supplier: null,
    },

    modal: {
        recyclingCodes: false,
        questionnaires: false,
        supplierList: false,
        productsOverview: false,
    },

    options: {
        language: [
            { label: 'English', value: 'en' },
            { label: 'Deutsch', value: 'de' },
        ],

        plasticPack: {
            materials: [
                { label: 'PET', value: 'pet' },
                { label: 'PE', value: 'pe' },
                { label: 'PP', value: 'pp' },
                { label: 'PS', value: 'ps' },
                { label: 'XPS', value: 'xps' },
                { label: 'EPS', value: 'eps' },
                { label: 'PVC', value: 'pvc' },
            ],
            materialTransparencies: [
                { label: 'Clear transparent', value: 'clear_transparent' },
                { label: 'Colored transparent', value: 'colored_transparent' },
                { label: 'Colored opaque', value: 'colored_opaque' },
            ],
            materialTypes: [
                { label: 'Flexible', value: 'flexible' },
                { label: 'Rigid', value: 'rigid' },
            ],
        },

        otherPack: {
            materials: [
                {
                    label: 'Metal',
                    value: 'metal',
                    creatable: false,
                    specifyName: false,
                    materialTypes: [
                        { label: 'Steel', value: 'steel' },
                        { label: 'Aluminium', value: 'aluminium' },
                        { label: 'Tinplate', value: 'tinplate' },
                        { label: 'Iron', value: 'iron' },
                    ],
                },
                { label: 'Beverage carton', value: 'beverage_carton' },
                { label: 'Biogenic materials', value: 'biogenic_materials' },
                {
                    label: 'Glass',
                    value: 'glass',
                    creatable: false,
                    specifyName: false,
                    materialTypes: [
                        { label: 'Clear', value: 'clear' },
                        { label: 'Colored', value: 'colored' },
                        { label: 'Composite', value: 'composite' },
                    ],
                },
                {
                    label: 'Paper / Cardboard / Carton',
                    value: 'paper_cardboard_carton',
                    creatable: true,
                    specifyName: false,
                    materialTypes: [
                        { label: 'Corrugated board', value: 'corrugated_board' },
                        { label: 'Moulded fibre', value: 'moulded_fibre' },
                        { label: 'Composite', value: 'composite' },
                    ],
                },
                {
                    label: 'Biodegradable²',
                    value: 'biodegradable',
                    creatable: false,
                    specifyName: true,
                    materialTypes: [
                        { label: 'Clear', value: 'clear' },
                        { label: 'Colored', value: 'colored' },
                    ],
                },
                {
                    label: 'Other materials³',
                    value: 'other_materials',
                    creatable: true,
                    specifyName: true,
                    materialTypes: [
                        { label: 'Raw wood, cork', value: 'raw_wood_cork' },
                        { label: 'Wood not raw, textiles', value: 'wood_not_raw_textiles' },
                        { label: 'Stoneware, porcelain, ceramics', value: 'stoneware_porcelain_ceramics' },
                    ],
                },
                {
                    label: 'Other compounds⁴',
                    value: 'other_compounds',
                    creatable: false,
                    specifyName: true,
                    materialTypes: [
                        { label: 'Clear transparent', value: 'clear_transparent' },
                        { label: 'Colored', value: 'colored' },
                    ],
                },
            ],
        },
    },

    isNonePlastic: false,

    form: {
        supplierId: userData?.user?.DynamicsSupplier?.id || '',
        skus: [],
        trayAvailable: false,
        dimensions: {
            indiv: {
                width: '',
                height: '',
                length: '',
            },
            outer: {
                width: '',
                height: '',
                length: '',
            },
        },
        isPlastic: false,
        plasticPack: {
            indiv: {
                materials: [],
            },
            outer: {
                materials: [],
            },
        },
        otherPack: {
            indiv: {
                materials: [],
            },
            outer: {
                materials: [],
            },
        },
        label: {
            ordered: true,
            width: '',
            height: '',
            weight: '',
            material: '',
        },
        recyclingCodes: {
            packType: '',
            indivPack: [],
            outerPack: [],
        },
        palette: {
            singleItem: '',
            trayPerBox: '',
            boxPerPalette: '',
        },
        confirmed: false,
        checked: false,
        outdated: 'not outdated',
        outdatedSkus: [],
        comment: '',
    }
};

export default localstate;