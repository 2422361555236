import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {observable} from 'mobx';
import moment from 'moment';
import {Switch} from '@material-ui/core';
import NavigationBar from '../NavigationBar/NavigationBar';
import {Container, Button, Section} from 'react-bulma-components';
import FetchView from '../../dumb/FetchView/FetchView';
import {toast} from 'react-toastify';
import {pdf, Document} from '@react-pdf/renderer';

import styles from './styles';
import translation from './translations';
import localstate from './localstate';
import PlasticPackaging from './Extension/PlasticPackaging';
import OtherPackaging from './Extension/OtherPackaging';
import RecyclingCodes from './Modal/RecyclingCodes';
import PackagingQuestionnaires from './Modal/PackagingQuestionnaires';
import SupplierList from './Modal/SupplierList';
import ProductsOverview from './Modal/ProductsOverview';
import PDFPage from './Extension/PDFPage';
import SupplierItemsStore from 'stores/Supplier/SupplierItemsStore';
import PackagingQuestionnaireStore from 'stores/Supplier/PackagingQuestionnaireStore';
import SupplierListStore from 'stores/Supplier/SupplierListStore';
import ProductsOverviewStore from 'stores/Supplier/ProductsOverviewStore';

import Section1 from './Section/Section1';
import Section2 from './Section/Section2';
import Section4 from './Section/Section4';
import Section5 from './Section/Section5';
import Section6 from './Section/Section6';
import Section7 from './Section/Section7';
import Section8 from './Section/Section8';
import SectionIntern from './Section/SectionIntern';

const localUserData = localStorage.getItem('userData') || '';
const userData = localUserData ? JSON.parse(localUserData) : {};
const userGroups = userData?.user?.usergroups ? userData.user.usergroups.map(x => x.group) : [];

@observer
class SupplierQuestionnaire extends Component {
    constructor() {
        super();
        this.defaultValue = {
            form: JSON.parse(JSON.stringify(this.localstate.form)),
        }
    }

    @observable accessor localstate = localstate;

    handleNestedSelect = (current = [], selected = [], optionalKeys = {}) => {
        if (!selected) selected = [];
        
        if (selected.length === 0) {
            current.splice(0, current.length);
        }

        for (const item of selected) {
            if (!current.map(x => x.value).includes(item.value)) {
                const {label, value} = item;
                current.push({ label, value, ...optionalKeys });
            }
        }

        current.forEach((item, i) => {
            if (!selected.map(x => x.value).includes(item.value)) {
                current.splice(i, 1);
            }
        });
    }

    createNewOption = (current, created, optionalKeys = {}) => {
        current.push({
            label: created,
            value: created.toLowerCase().replace(/[^a-züöäß0-9 ]/gi, '').replace(/\s{2,}|\s/g, '_'),
            ...optionalKeys,
        });
    }

    translateLabel = (arr = null, language = this.localstate?.language?.value) => {
        const tl = translation[language || 'en'];
        return arr ? arr.map(x => ({ ...x, label: tl.section3[x.value] || x.label })) : null;
    }

    handleInput = (object, key, e) => {
        if (object[key] !== undefined) object[key] = e.target.value;
    }

    error = (value = '', condition = true) => {
        const {submitted} = this.localstate;

        if ((!value || value?.length === 0) && condition) {
            return (submitted) ? 'error iserror' : 'iserror';
        } else {
            return null;
        }
    }
    
    onFileChange = async (e) => {
        const {filesToUpload} = this.localstate;
        const newFiles = e.target?.files || [];
        const currentFiles = filesToUpload.filter(x => ![...newFiles].map(x => x.name).includes(x.name));

        this.localstate.filesToUpload = [...currentFiles, ...newFiles];
        e.target.value = null;
    }

    submitOrder = async (action) => {
        const {selected, form, isNonePlastic, adminAccess, files, filesToUpload, language} = this.localstate;
        const tl = translation[language.value];
        const defaultForm = this.defaultValue.form;

        let uploadRequired = false;
        const plasticTypes = ['indiv', 'outer'];

        firstLoop:
        for (const plasticType of plasticTypes) {
            for (const material of form.plasticPack[plasticType].materials) {
                for (const transparency of material.materialTransparencies) {
                    for (const materialType of transparency.materialTypes) {
                        if (Number(materialType.recycledContent) > 0) {
                            uploadRequired = true;
                            break firstLoop;
                        }
                    }
                }
            }
        }
        
        if (adminAccess && (!selected.questionnaire && !selected.supplier)) {
            toast.warning(tl.errorAdminCreation);
            this.localstate.modal.supplierList = true;
            return;
        }

        if (isNonePlastic) {
            form.plasticPack.outer = defaultForm.plasticPack.outer;
        }

        if (!form.trayAvailable) {
            form.dimensions.outer = defaultForm.dimensions.outer;
            form.plasticPack.outer = defaultForm.plasticPack.outer;
            form.otherPack.outer = defaultForm.otherPack.outer;
            form.recyclingCodes.outerPack = defaultForm.recyclingCodes.outerPack;
        }

        if (!form.isPlastic) {
            form.plasticPack = defaultForm.plasticPack;
        }

        this.localstate.submitted = true;

        const errors = document.querySelectorAll('.iserror')?.length || 0;

        if (errors > 0) {
            toast.error(tl.error);
            return;
        }

        if (uploadRequired && (files.length === 0 && filesToUpload.length === 0)) {
            toast.error(tl.section7.uploadRequired);
            return;
        }

        const requestBody = {
            ...form,
            dynamicsSupplierId: form.supplierId,
        }

        delete requestBody.supplierId;
        delete requestBody.confirmed;

        this.localstate.loading = true;

        try {
            let res = null;

            PackagingQuestionnaireStore.setUrl('supplier/packagingquestionnaire');

            if (selected.questionnaire && action !== 'creation') {
                requestBody.id = selected.questionnaire.id;
                res = await PackagingQuestionnaireStore.updateItem(requestBody);
            } else {
                res = await PackagingQuestionnaireStore.createItem(requestBody);
            }

            if (!res.success) throw res.error?.message || res.error || res.message || JSON.stringify(res) || 'An error occurred';

            if (filesToUpload.length > 0) {
                const uploadRes = await PackagingQuestionnaireStore.uploadFiles(res.data?.id, filesToUpload);

                if (!uploadRes.success) {
                    toast.warning('Questionnaire submitted successfully, but file upload failed');
                    this.reset();
                    throw uploadRes.error?.message || uploadRes.error || uploadRes.message || JSON.stringify(uploadRes) || 'Error uploading file';
                }
            }

            await ProductsOverviewStore.fetchData(true);

            toast.success(selected.questionnaire && action !== 'creation' ? tl.successUpdate : tl.successSubmit);
            this.reset();
            this.localstate.loading = false;
        } catch (error) {
            console.error(error);
            toast.error(error);
            this.localstate.loading = false;
        }
    }

    reset = () => {
        const defaultForm = JSON.parse(JSON.stringify(this.defaultValue.form));

        this.localstate.form = defaultForm;
        this.localstate.submitted = false;
        this.localstate.modal.questionnaires = false;
        this.localstate.modal.supplierList = false;
        this.localstate.selected.questionnaire = null;
        this.localstate.selected.supplier = null;
        this.localstate.files = [];
        this.localstate.filesToUpload = [];
    }

    async downloadPDF() {
        try {
            const {pdfOutput, selected} = this.localstate;
            const selectedQuestionnaire = selected.questionnaire?.DynamicsSupplier;
            const link = document.createElement('a');
            this.localstate.loading = true;

            const blob = await pdf(
                <Document>
                    <PDFPage parentstate={this.localstate}/>
                </Document>
            ).toBlob();

            link.href = window.URL.createObjectURL(blob);
            link.download = `Questionnaire ${selected.questionnaire?.id} (${selectedQuestionnaire?.vendorNr}).pdf`;

            if (pdfOutput === 'blob') window.open(link.href, '_blank');
            if (pdfOutput === 'download') link.click();

            toast.success('Questionnaire downloaded successfully');
            this.localstate.loading = false;
        } catch (error) {
            this.localstate.loading = false;
            toast.error(`PDF could not be created: ${error}`);
        }
    }

    setOutdatedValue = () => {
        const {form} = this.localstate;
        const skus = form.skus.map(sku => sku.value);
        const outdatedSkus = form.outdatedSkus.map(sku => sku.value);

        const isCompletelyOutdated = skus.every(sku => outdatedSkus.includes(sku)) && skus.length > 0;
        const isPartiallyOutdated = skus.some(sku => outdatedSkus.includes(sku)) && skus.length > 0;

        const outdatedState = isCompletelyOutdated ? 'outdated' : isPartiallyOutdated ? 'partially outdated' : 'not outdated';
        this.localstate.form.outdated = outdatedState;
    }

    render() {
        const {loading, adminAccess, selected, form, language} = this.localstate;
        const tl = translation[language.value];
        const selectedQuestionnaire = selected.questionnaire?.DynamicsSupplier;

        return (<>
            <NavigationBar title='Supplier Questionnaire'></NavigationBar>

            <FetchView stores={[SupplierItemsStore, PackagingQuestionnaireStore, SupplierListStore, ProductsOverviewStore]}>
                <Container style={{ marginTop: 20 }}>
                {
                (adminAccess || userGroups.includes('Supplier')) &&
                    <div style={styles.row}>
                        <Button
                            fullwidth={true}
                            color='primary'
                            onClick={() => this.localstate.modal.questionnaires = true}
                        >
                            { adminAccess ? `${tl.questionnaires.buttonViewAdmin}` : `${tl.questionnaires.buttonViewSelf}` }
                        </Button>
                    {
                        adminAccess &&
                            <>
                                <Button
                                    fullwidth={true}
                                    color='info'
                                    onClick={() => this.localstate.modal.supplierList = true}
                                >
                                    {tl.supplierList.button}
                                </Button>
                                <Button
                                    fullwidth={true}
                                    color='info'
                                    onClick={() => this.localstate.modal.productsOverview = true}
                                >
                                    {tl.productsOverview.button}
                                </Button>
                            </>
                    }
                    </div>
                }

                {
                    selected.questionnaire &&
                        <div style={styles.info}>
                            <h2 style={{ fontSize: 20, textAlign: 'center' }}>
                                {tl.questionnaires.selectedInfo}.<br/>
                                ({selectedQuestionnaire?.vendorNr}, {selectedQuestionnaire?.name}, {tl.createdAt} {moment(selectedQuestionnaire?.createdAt).format(tl.dateFormat)})
                            </h2>
                            <div style={{ ...styles.row, marginTop: 5 }}>
                                {
                                adminAccess &&
                                    <Button
                                        color='primary'
                                        loading={loading}
                                        disabled={!selectedQuestionnaire}
                                        onClick={() => this.downloadPDF()}
                                    >
                                        {tl.pdfButton}
                                    </Button>
                                }
                                <Button
                                    color='danger'
                                    onClick={() => this.reset()}
                                >
                                    {tl.cancel}
                                </Button>
                            </div>
                        </div>
                }

                {
                    selected.supplier &&
                        <div style={styles.info}>
                            <h2 style={{ fontSize: 20, textAlign: 'center' }}>
                                {tl.supplierList.selectedInfo}.<br/>
                                ({selected.supplier?.vendorNr}, {selected.supplier?.name})
                            </h2>
                            <div style={{ ...styles.row, marginTop: 5 }}>
                                <Button
                                    color='danger'
                                    onClick={() => this.localstate.selected.supplier = null}
                                >
                                    {tl.cancel}
                                </Button>
                            </div>
                        </div>
                }
                
                {
                    (adminAccess && form.outdated !== 'not outdated') &&
                        <div style={{
                            ...styles.info,
                            ...(form.outdated === 'partially outdated' && styles.infoYellow),
                            ...(form.outdated === 'outdated' && styles.infoRed)
                        }}>
                            <p style={{ fontSize: 20 }}>{tl.sectionIntern.outdatedStatus[form.outdated]}</p>
                        </div>
                }

                    <Section1 parent={this}/>
                    <Section2 parent={this}/>

                    <Section style={styles.section}>
                        <h1 style={styles.headline}>3. {tl.section3.headline}</h1>
                        <div style={styles.noteWarning}>{tl.section3.headNote}</div>
                        <div style={styles.switchBox}>
                            <h2 style={styles.switchBoxText}>{tl.section3.checkboxText}:</h2>
                            <Switch
                                disabled={loading}
                                checked={form.isPlastic}
                                onChange={e => form.isPlastic = e.target.checked}
                            />
                        </div>
                        { form.isPlastic && <PlasticPackaging parent={this}/> }
                        <OtherPackaging parent={this}/>
                    </Section>

                    <Section4 parent={this}/>
                    <Section5 parent={this}/>
                    <Section6 parent={this}/>
                    <Section7 parent={this}/>
                    { adminAccess && <SectionIntern parent={this}/> }
                    <Section8 parent={this}/>

                </Container>

                <RecyclingCodes parent={this}/>
                <PackagingQuestionnaires parent={this} store={PackagingQuestionnaireStore}/>
                { adminAccess && <SupplierList parent={this} store={SupplierListStore}/> }
                { adminAccess && <ProductsOverview parent={this} store={ProductsOverviewStore}/> }
            
            </FetchView>

            <input
                id='file-upload'
                type='file'
                disabled={loading}
                style={{ display: 'none' }}
                multiple
                onChange={(e) => this.onFileChange(e)}
            />
        </>);
    }
}

export default SupplierQuestionnaire;